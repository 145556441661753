.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    background-color: #1a1a1a;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #bada55;
}
.appBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav {
    list-style: none;
    display: flex;
}
.nav li {
    padding-right: 16px;
}
